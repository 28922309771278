import React from "react";
import openPositions from '../../../data/openPositions.json'

function CareerItem({currentSelection}){
 return(
  <div className="row position-list">
    {
      openPositions.map((el)=> {
        if((currentSelection == "all" || (currentSelection ==el.group))){
            return(
              <div style={{marginTop: 14}} className="col-md-6">
                <div className="position-item wow fadeInUp">
                  <h2>{el.title}<b>.</b></h2>
                  <span>{el.location}</span>
                  <span>{el.isFull}</span>
                  <div className="links">
                      <a href="mailto:jobs@361.ventures">jobs@361.ventures <img src="./images/arrow-top-right.svg" /></a>
                  </div>
                  <a href="mailto:jobs@361.ventures" className="detail-btn">
                      Apply this via E-mail
                  </a>
                </div>
              </div>
            )
          }
      })
    }
  </div>
 )
}

export default CareerItem;