import React from "react";
import employees from "../../../data/employees.json"

function EmployeeItem (){
  return(
    <div className="row teams">
  {
  employees.map(el =>{
    return(
      <div className="col-md-4 person wow fadeInUp">
          <div className="person-img">
              <img src={el.avatar}/>
          </div>
          <div className="person-info">
              <h3>{el.name}<b>.</b></h3>
              <span>{el.title}</span>
          </div>
      </div>
    )
  })
}
  </div> 
  )
 
}

export default EmployeeItem;