import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Footer from "../Footer";
import Social from "../Social";
import openPositions from "../../data/openPositions.json";
import CareerItem from "./components/CareerItem";
function Career() {
  const [currentSelection, setCurrentSelection] = useState("all");

  
  return (
    <div>
      <nav className="wow container page-nav">
        <Link className="wow" to={"/"}>
          <img width="72" height="31" src="./images/361.svg" />
        </Link>
        <Link to={"/about-us"}>about us</Link>
        <Link to={"/career"}>career</Link>
      </nav>

      <div className="container home-about-box about-page">
        <span className="page-title">CAREER 361</span>
        <h4>
          open positions<span>.</span>
        </h4>
        <div className="row">
          <div className="col-md-7 about-content">
            Check out our open positions, if you like a position just send us an
            email with the name of the position & your CV and we get back to you
            asap.
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row position-nav" id="position-nav">
          {[
            {
              title: "All Positions",
              group: "all",
            },
            {
              title: "Engineering",
              group: "engineer",
            },
            {
              title: "Product",
              group: "product",
            },
            {
              title: "Design",
              group: "design",
            },
            {
              title: "Growth",
              group: "growth",
            }
          ].map((el) => {
            return (
              <span
                className={
                  el.group === currentSelection
                    ? "wow fadeIn item active active"
                    : "wow fadeIn item"
                }
                onClick={() => setCurrentSelection(el.group)}
              >
                {el.title}
              </span>
            );
          })}
        </div>

        <CareerItem currentSelection={currentSelection} />

        <div className="container wow fadeInUp">
          <div className="row create-box">
            <div className="col-md-6">
              <span>Let’s build together products</span>
            </div>
            <div className="col-md-6 ">
              <p>That are enjoyed by millions of people.</p>
            </div>
          </div>
        </div>

        <div className="mail-box wow fadeInUp">
          <div className="container">
            <div className="row ">
              <div className="col">
                <span>FOR PRESS & INFO</span>
                <a href="mailto:info@361.ventures">info@361.ventures</a>
              </div>
              <div className="col">
                <span>FOR TALENTS</span>
                <a href="mailto:jobs@361.ventures">jobs@361.ventures</a>
              </div>
            </div>
          </div>
        </div>
        <Social />
        <Footer />
      </div>
    </div>
  );
}

export default Career;
