import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Footer from "../Footer";
import Social from "../Social";

function Home() {
  return (

      <div>
        <nav>
          <Link to={"/about-us"}>about us</Link>
          <Link className="wow" to={"/"}><img width="72" height="31" src="./images/361.svg" /></Link>
          <Link to={"/career"}>career</Link>
        </nav>


        <div className="container welcome wow fadeIn">
          <img
            className="plus1 wow fadeInLeft "
            data-depth="3"
            data-tilt
            src="./images/plus1.webp"
          />
          <img
            className="plus2 wow fadeIn"
            data-depth="0.10"
            data-tilt
            src="./images/plus2.webp"
          />
          <img
            className="oval1 wow fadeInUp"
            data-tilt
            src="./images/oval1.webp"
          />
          <img
            className="oval2 wow fadeInDown"
            data-tilt
            src="./images/oval2.webp"
          />
          <h1>
            building the future<span style={{color:"#48e197"}}>.</span>
          </h1>
          <h3>
            creating the next gen of <br />
            digital ventures.
          </h3>
          <Link to="/about-us">Discover more</Link>
        </div>

        <div className="container total-box">
          <span className="total-download wow fadeInUp">Users</span>
          <div className="row">
            <div className="col-md-7 wow fadeInUp">
              <span className="download-count wow fadeInLeft">+30 million</span>
            </div>
            <div className="col-md-4 wow fadeInUp">
              <p>
                we built products that excite us and millions of other people.
              </p>
            </div>
          </div>
        </div>

        <div className="container clients">
          <div className="row clients-item wow fadeInUp">
            <div className="col-md-6">
              <div className="clients-item-info">
                <img src="./images/silk-app.png" />
                <h3>Silk.</h3>
              </div>
              <p>
                {" "}
                <b>
                  {" "}
                  Look 5 Years younger with just 3 minutes of Faceyoga a day!
                </b>
                With Silk. you can start seeing results within 1 week our
                personalized workouts help you to remain youthful.
              </p>
              <a
                href="https://apps.apple.com/us/app/silk-faceyoga-excercises/id1513975667"
                target="_blank"
                className="store"
              >
                <img width="216" height="64" src="./images/appstore.png" />
              </a>
            </div>
            <div className="col-md-4 offset-md-2 client-img">
              <img src="./images/silk-ss.png" />
            </div>
          </div>
          <div className="row clients-item wow fadeInUp">
            <div className="col-md-6">
              <div className="clients-item-info">
                <img src="./images/photo-collage-app.png" />
                <h3>Photo Collage & Pic Grid Maker</h3>
              </div>
              <p>
                Create exciting collages by putting your own photos with frames
                and effects together in seconds to create the perfect look.
              </p>
              <a
                href="https://apps.apple.com/tr/app/photo-collage-pic-grid-maker/id1500743649"
                target="_blank"
                className="store"
              >
                <img width="216" height="64" src="./images/appstore.png" />
              </a>
            </div>
            <div className="col-md-4 offset-md-2 client-img">
              <img src="./images/photo-collage-ss.png" />
            </div>
          </div>
          <div className="row clients-item wow fadeInUp">
            <div className="col-md-6">
              <div className="clients-item-info">
                <img src="./images/reportly-app.png" />
                <h3>Reportly</h3>
              </div>
              <p>
                Track who unfollowed or blocked you. See who visited your
                profile & watch anonymous stories! Reportly is the safest and
                most accurate profile analytics tool for your account.
              </p>
              <a
                href="https://apps.apple.com/tr/app/reportly-unfollower-tracker/id1455778089"
                target="_blank"
                className="store"
              >
                <img width="216" height="64" src="./images/appstore.png" />
              </a>
            </div>
            <div className="col-md-4 offset-md-2 client-img">
              <img src="./images/reportly-ss.png" />
            </div>
          </div>
          <div className="clients-more-box wow fadeInDown">
            <div>
              <span>+20</span>
              <p>more digital products</p>
            </div>
            <span className="more-text">
              Built the next gen digital venture with us.{" "}
            </span>
          </div>
        </div>
        {/* <div className="container home-about-box wow fadeInUp">
        <h4>Building products that excites us<span>.</span></h4>
        <div className="row">
            <div className="col-md-6">
                <p>Our Venture Studio is remotely distributed and brings together
                    technology, talent & capital to create innovative ventures. </p>
            </div>
            <div className="col-md-6  home-about-button-box">
                <a href="">Join 361.</a>
            </div>
        </div>

      </div>
      <div className="slider-box wow fadeInRight">
        <div className="about-slider">
            <div>
                <a href="./images/work/work-5.png" >
                    <img src="./images/work/work-5.png" />
                    <img className="enlarge" src="./images/enlarge.svg" />
                </a>
            </div>
            <div>
                <a href="./images/work/work-4.png" data-fancybox="slider">
                    <img src="./images/work/work-4.png" />
                    <img className="enlarge" src="./images/enlarge.svg" />
                </a>
            </div>
            <div>
                <a href="./images/work/work-1.png" data-fancybox="slider">
                    <img src="./images/work/work-1.png" />
                    <img className="enlarge" src="./images/enlarge.svg" />
                </a>
            </div>
            <div>
                <a href="./images/work/work-2.png" data-fancybox="slider">
                    <img src="./images/work/work-2.png" />
                    <img className="enlarge" src="./images/enlarge.svg" />
                </a>
            </div>
            <div>
                <a href="./images/work/work-3.png" data-fancybox="slider">
                    <img src="./images/work/work-3.png" />
                    <img className="enlarge" src="./images/enlarge.svg" />
                </a>
            </div>
        </div>
    </div> */}

        <div className="container wow fadeInUp">
          <div className="row create-box">
            <div className="col-md-6">
              <span>Let’s build together products</span>
            </div>
            <div className="col-md-6 ">
              <p>That are enjoyed by millions of people.</p>
            </div>
          </div>
        </div>
        <div className="mail-box wow fadeInDown">
          <div className="container">
            <div className="row ">
              <div className="col">
                <span>FOR PRESS & INFO</span>
                <a href="mailto:info@361.ventures">info@361.ventures</a>
              </div>
              <div className="col">
                <span>FOR TALENTS</span>
                <a href="mailto:jobs@361.ventures">jobs@361.ventures</a>
              </div>
            </div>
          </div>
        </div>
       <Social/>
       <Footer/>
      </div>
  );
}

export default Home;
