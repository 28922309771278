function Social() {
  return (
    <footer className="container">
      <a
        href="https://www.facebook.com/361-Ventures-1264389853714783"
        target="_blank"
      >
        <span>/361ventures</span>
        <i className="fab fa-facebook-f"></i>
      </a>
      <a href="https://twitter.com/361Ventures" target="_blank">
        <span>/361ventures</span>
        <i className="fab fa-twitter"></i>
      </a>
      <a href="https://www.linkedin.com/company/361ventures/" target="_blank">
        <span>/361ventures</span>
        <i className="fab fa-linkedin-in"></i>
      </a>
      <a href="https://www.instagram.com/361.ventures/" target="_blank">
        <span>/361ventures</span>
        <i className="fab fa-instagram"></i>
      </a>
    </footer>
  );
}

export default Social;
