import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Footer from "../Footer";
import Social from "../Social";
import employees from "../../data/employees.json"
import EmployeeItem from "./components/EmployeeItem";
function AboutUs() {
  return (
    <div>
			<nav className="wow container page-nav">
        <Link className="wow" to={"/"}><img width="72" height="31" src="./images/361.svg" /></Link>
        <Link to={"/about-us"}>about us</Link>
        <Link to={"/career"}>career</Link>
			</nav>

    <div className="container home-about-box about-page">
        <span className="page-title">ABOUT 361</span>
        <h4>building the future.<span>.</span></h4>
        <div className="row">
            <div className="col-md-6">
                <p>We are a remote distributed team from all around the world & we dare to create next gen digital ventures </p>
            </div>
            <div className="col-md-6 about-button">
                <Link to={"/career"}>See Open Positions</Link>
            </div>
        </div>

    </div>
   { /*<div className="slider-box wow fadeInRight">
        <div className="about-slider">
            <div>
                <a href="./images/work/work-5.png" data-fancybox="slider">
                    <img src="./images/work/work-5.png" />
                    <img className="enlarge" src="./images/enlarge.svg" />
                </a>
            </div>
            <div>
                <a href="./assets/images/work/work-4.png" data-fancybox="slider">
                    <img src="./images/work/work-4.png" />
                    <img className="enlarge" src="./images/enlarge.svg" />
                </a>
            </div>
            <div>
                <a href="./assets/images/work/work-1.png" data-fancybox="slider">
                    <img src="./images/work/work-1.png" />
                    <img className="enlarge" src="./images/enlarge.svg" />
                </a>
            </div>
            <div>
                <a href="./assets/images/work/work-2.png" data-fancybox="slider">
                    <img src="./images/work/work-2.png" />
                    <img className="enlarge" src="./images/enlarge.svg" />
                </a>
            </div>
            <div>
                <a href="./assets/images/work/work-3.png" data-fancybox="slider">
                    <img src="./images/work/work-3.png" />
                    <img className="enlarge" src="./images/enlarge.svg" />
                </a>
            </div>
        </div>
  </div>*/}
    <div className="container pr-0 wow fadeInUp">
        <div className=" row about-detail-box">
            <div className="col-md-5">
                <p>361. started 2019 with the love to build viral products that reach millions of people without having resources. Since then we’ve built 20+ Products that are successful & still growing and we’re still building exciting products as a team of people that love to hack,learn and try new things. By 2029 we want to reach a billion people with our products. </p>
            </div>
            <div className="col-md-5 offset-md-1">
                <img src="./images/t.svg" />
                <span>If you're offered a seat on a rocket ship, don't ask what seat! Just get on.</span>
                <div className="ceo-box">
                    <div>
                        <span>Sheryl Sandberg</span>
                        <small>Business Woman</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
       <EmployeeItem/>
    </div>
    <div className="container wow fadeInUp">
        <div className="row create-box">
            <div className="col-md-6">
                <span>Let’s build together products</span>
            </div>
            <div className="col-md-6 ">
                <p>That are enjoyed by millions of people.</p>
            </div>
        </div>
    </div>
    <div className="mail-box wow fadeInUp">
        <div className="container">
            <div className="row ">
                <div className="col">
                    <span>FOR PRESS & INFO</span>
                    <a href="mailto:info@361.ventures">info@361.ventures</a>
                </div>
                <div className="col">
                    <span>FOR TALENTS</span>
                    <a href="mailto:jobs@361.ventures">jobs@361.ventures</a>
                </div>
            </div>
        </div>
    </div>
   <Social/>
   <Footer/>
    </div>   
  );
}

export default AboutUs;

/*        <div className="col-md-4 person wow fadeInUp">
                <div className="person-img">
                    <img src="./images/team/man-1.png" />
                </div>
                <div className="person-info">
                    <h3>Ömer Dogan<b>.</b></h3>
                    <span>Cto & Founder</span>
                </div>
            </div>
            <div className="col-md-4 person wow fadeInUp">
                <div className="person-img">
                    <img src="./images/team/man-3.png" />
                </div>
                <div className="person-info">
                    <h3>Marcel Stuhr<b>.</b></h3>
                    <span>Head of 361.Studio</span>
                </div>
            </div>
            <div className="col-md-4 person wow fadeInUp">
                <div className="person-img">
                    <img src="./images/team/man-4.png" />
                </div>
                <div className="person-info">
                    <h3>Tunca Tuncay Arda<b>.</b></h3>
                    <span>Head of Vision</span>
                </div>
            </div>
            <div className="col-md-4 person wow fadeInUp">
                <div className="person-img">
                    <img src="./images/team/man-5.png" />
                </div>
                <div className="person-info">
                    <h3>Naji Alfar<b>.</b></h3>
                    <span>Lead 3d-Designer</span>
                </div>
            </div>
            <div className="col-md-4 person wow fadeInUp">
                <div className="person-img">
                    <img src="./images/team/woman-2.png" />
                </div>
                <div className="person-info">
                    <h3>Ezgi Erguney<b>.</b></h3>
                    <span>Growth Manager</span>
                </div>
            </div>
            <div className="col-md-4 person wow fadeInUp">
                <div className="person-img">
                    <img src="./images/team/man-7.png" />
                </div>
                <div className="person-info">
                    <h3>Resul Alici<b>.</b></h3>
                    <span>Software Developer</span>
                </div>
            </div>
            <div className="col-md-4 person wow fadeInUp">
                <div className="person-img">
                    <img src="./images/team/man-8.png" />
                </div>
                <div className="person-info">
                    <h3>Peter Can Sunbul<b>.</b></h3>
                    <span>3D-Designer</span>
                </div>
            </div>
            <div className="col-md-4 person wow fadeInUp">
                <div className="person-img">
                    <img src="./images/team/woman-1.png" />
                </div>
                <div className="person-info">
                    <h3>Gizem Alpaslan<b>.</b></h3>
                    <span>Motion Graphic Designer</span>
                </div>
            </div>
            <div className="col-md-4 person wow fadeInUp">
                <div className="person-img">
                    <img src="./images/team/man-6.png" />
                </div>
                <div className="person-info">
                    <h3>Salim Gursoy<b>.</b></h3>
                    <span>Senior Product Designer</span>
                </div>
            </div>
            <div className="col-md-4 wow fadeInUp ">
                <div className="open-position">
                    <span>do yo want to
                        join the rocketship?
                        </span>
                    <a href="#">See Open Positions</a>
                </div>
    </div>*/