import './App.css';
import NavBar from './pages/NavBar';
import Social from './pages/Social';
import Footer from './pages/Footer';
import Home from './pages/Home';
import Career from './pages/Career';
import AboutUs from './pages/AboutUs';
import NavBarLeft from './pages/NavBar/components/navBarLeft';
function App() {
  return (
    <div>
    {
      // <Home/> 
      // ? 
      <NavBar/> 
      // : ""
    }
    </div>
  );
}

export default App;
