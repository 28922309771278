import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function Footer (){
  return(
    <div>
    <nav className="animate__animated animate__fadeInDown">
    <nav>
          <Link to={"/about-us"}>about us</Link>
          <Link className="wow" to={"/"}><img width="72" height="31" src="./images/361.svg" /></Link>
          <Link to={"/career"}>career</Link>
        </nav>
</nav>
<div className="text-center footer-logo">
    <img src="./images/logo-grey.webp" />
</div>
</div>
  )
}

export default Footer;