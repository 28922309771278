import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AboutUs from '../AboutUs'
import Career from '../Career'
import Home from "../Home";


function NavBar() {
  return (
    <Router> 
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route path="/career" component={Career} />
      </Switch>
    </Router>
   
  );
}


export default NavBar;

